import yup from 'lib/yup';
import { createQuestion } from 'qualification/schema/questions/utils';

export const DEFAULT_DIETARY_PERCENTAGE = {
  carnivore: 0.3,
  vegetarian: 0.5,
  vegan: 0.2,
} as const;

export const numberOfVegetariansQuestion = createQuestion({
  id: 'number_of_vegetarians',
  getValidator: () => yup.number().required().min(0),
  content: {
    titleEn: 'Vegetarians',
    titleDe: 'Vegetarier',
    image: '/artifacts/images/survey/dietary_restrictions/vegetarian.webp',
  },
});

export const numberOfVegansQuestion = createQuestion({
  id: 'number_of_vegans',
  getValidator: () => yup.number().required().min(0),
  content: {
    titleEn: 'Vegans',
    titleDe: 'Veganer',
    image: '/artifacts/images/survey/dietary_restrictions/vegan.webp',
  },
});

export const numberOfCarnivoresQuestion = createQuestion({
  id: 'number_of_carnivore',
  getValidator: () => yup.number().required().min(0),
  content: {
    titleEn: 'Carnivore',
    titleDe: 'Fleischesser',
    image: '/artifacts/images/survey/dietary_restrictions/carnivore.webp',
  },
});

import yup from 'lib/yup';
import { createSelectorQuestion } from 'qualification/schema/questions/utils';

export const LEAD_TYPES = ['private', 'business'] as const;
export type LeadType = typeof LEAD_TYPES[number];

export const LEAD_TYPE_ITEMS = [
  {
    labelEn: 'Private',
    labelDe: 'Privat',
    value: 'private',
    image: '/artifacts/images/survey/lead_type/private_lead.webp',
  },
  {
    labelEn: 'Business',
    labelDe: 'Geschäftlich',
    value: 'business',
    image: '/artifacts/images/survey/lead_type/business_lead.webp',
  },
] as const;

export const leadTypeQuestion = createSelectorQuestion({
  id: 'lead_type',
  getDefaultValue: () => 'business' as const,
  getValidator: () =>
    yup
      .mixed<LeadType>()
      .oneOf([...LEAD_TYPES])
      .required(),
  condition: (isLoggedIn: boolean) => !isLoggedIn,
  content: {
    titleEn: 'Is it for a private or business event?',
    titleDe: 'Ist es ein privater oder ein geschäftlicher Anlass?',
  },
  getItems: () => [...LEAD_TYPE_ITEMS],
});

export type LeadTypeQuestion = typeof leadTypeQuestion;

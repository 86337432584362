import { getFallbackForLocale, localeResources } from 'i18n';
import { algoliaAttributeNameForLocale } from 'shared/helpers/localizedFieldNames';

class LocalizedAlgoliaAttributes {
  constructor({ filters, locale }) {
    this.filters = filters;
    this.locale = locale;
  }

  generateLocalizedFieldValues(attributeName) {
    const algoliaLocaleResources =
      localeResources[getFallbackForLocale(this.locale)]['common']['algolia'];

    return Object.entries(algoliaLocaleResources[attributeName]).reduce(
      (result, [key, value]) =>
        (this.filters[attributeName] || []).includes(key)
          ? [...result, value]
          : result,
      []
    );
  }

  localizedFieldName(attributeName) {
    return algoliaAttributeNameForLocale(this.locale)(attributeName);
  }
}

export default LocalizedAlgoliaAttributes;

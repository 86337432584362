import { QuestionId } from 'qualification/schema/model';
import {
  pricePerPersonQuestion,
  totalBudgetQuestion,
} from 'qualification/schema/questions/budgetQuestion';
import { cateringCategoriesQuestion } from 'qualification/schema/questions/cateringCategoriesQuestion';
import { cityQuestion } from 'qualification/schema/questions/cityQuestion';
import {
  customerTierQuestion,
  rareCustomerTierQuestion,
} from 'qualification/schema/questions/customerTierQuestion';
import {
  numberOfCarnivoresQuestion,
  numberOfVegansQuestion,
  numberOfVegetariansQuestion,
} from 'qualification/schema/questions/dietaryRestrictionsQuestion';
import {
  dateQuestion,
  isMultipleDaysQuestion,
  isRecurringQuestion,
} from 'qualification/schema/questions/eventDateQuestion';
import { leadTypeQuestion } from 'qualification/schema/questions/leadTypeQuestion';
import { peopleCountQuestion } from 'qualification/schema/questions/peopleCountQuestion';

export const questions = {
  leadType: leadTypeQuestion,
  peopleCount: peopleCountQuestion,
  totalBudget: totalBudgetQuestion,
  pricePerPerson: pricePerPersonQuestion,
  city: cityQuestion,
  date: dateQuestion,
  isRecurring: isRecurringQuestion,
  isMultipleDays: isMultipleDaysQuestion,
  cateringCategories: cateringCategoriesQuestion,
  numberOfVegans: numberOfVegansQuestion,
  numberOfVegetarians: numberOfVegetariansQuestion,
  numberOfCarnivore: numberOfCarnivoresQuestion,
  customerTier: customerTierQuestion,
  rareCustomerTierQuestion: rareCustomerTierQuestion,
};

/**
 * A record of questions where their ids are the keys
 */
export const SCHEMA_QUESTIONS = createQuestions({
  lead_type: leadTypeQuestion,
  min_order_number: peopleCountQuestion,
  caterer_minimum_order_value: totalBudgetQuestion,
  price_per_person: pricePerPersonQuestion,
  city: cityQuestion,
  event_date: dateQuestion,
  has_recurring_need: isRecurringQuestion,
  is_multi_day: isMultipleDaysQuestion,
  catering_categories: cateringCategoriesQuestion,
  number_of_vegans: numberOfVegansQuestion,
  number_of_vegetarians: numberOfVegetariansQuestion,
  number_of_carnivore: numberOfCarnivoresQuestion,
  customer_tier: customerTierQuestion,
  rare_customer_tier: rareCustomerTierQuestion,
});

function createQuestions<T extends Record<QuestionId, unknown>>(obj: T) {
  return obj;
}

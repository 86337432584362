import {
  AnsweringQualificationState,
  Answers,
} from 'qualification/context/model';
import { answerQuestion } from 'qualification/context/reducer/answerQuestion';
import { disableStep } from 'qualification/context/reducer/utils';
import { cateringCategoriesQuestion } from 'qualification/schema/questions/cateringCategoriesQuestion';
import {
  cityQuestion,
  cityStep,
} from 'qualification/schema/questions/cityQuestion';
import { typedEntries } from 'shared/helpers/object';

export function answerInitialQuestions(
  state: AnsweringQualificationState,
  answers: Answers | undefined
) {
  let stateWithAnswers = { ...state };

  if (answers) {
    const answerTuples = typedEntries(answers);

    answerTuples.forEach(([answerId, answerValue]) => {
      stateWithAnswers = answerQuestion(
        stateWithAnswers,
        { id: answerId, value: answerValue },
        { storeIfInvalid: false }
      );

      if (state.qualification.selectedMenu) {
        stateWithAnswers = answerQuestion(
          stateWithAnswers,
          {
            id: 'catering_categories',
            value: state.qualification.selectedMenu.cateringCategories,
          },
          { storeIfInvalid: false }
        );
      }
    });
  }

  // Handle If only a single item is supported

  const funnelHasCityQuestion = state.qualification.steps.find(
    (q) => q.id === cityStep.id
  );
  if (funnelHasCityQuestion) {
    const items = cityQuestion.getItems({
      answers: answers || {},
      selectedCaterer: stateWithAnswers.qualification.selectedCaterer,
    });
    if (items?.length === 1) {
      stateWithAnswers = answerQuestion(
        stateWithAnswers,
        { id: 'city', value: items[0].value },
        { storeIfInvalid: false }
      );
      disableStep(stateWithAnswers, 'city');
    }
  }

  const funnelHasCateringCategoryQuestion = state.qualification.steps.find(
    (q) => q.id === cateringCategoriesQuestion.id
  );
  if (funnelHasCateringCategoryQuestion) {
    const items = cateringCategoriesQuestion.getItems({
      answers: answers || {},
      selectedCaterer: state.qualification.selectedCaterer,
    });
    if (items.length === 1) {
      stateWithAnswers = answerQuestion(
        stateWithAnswers,
        {
          id: 'catering_categories',
          value: items[0].value,
        },
        { storeIfInvalid: false }
      );

      disableStep(stateWithAnswers, 'catering_categories');
    }
  }

  return stateWithAnswers;
}

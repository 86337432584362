import { useCallback, useEffect, useRef, useState } from 'react';

import { EXPERIMENT, isVariationActive, Variation } from 'lib/ablyft';
import { QualificationState } from 'qualification/context/model';
import { QualificationActions } from 'qualification/context/reducer';
import useCurrentUser from 'qualification/hooks/useCurrentUser';
import { isQualifiedForContactForm } from 'qualification/hooks/useQualifyUser';
import { CUSTOMER_TIER_CONTROL_VALUE } from 'qualification/schema/questions/customerTierQuestion';

export const useTierExperiment = (
  state: QualificationState,
  actions: QualificationActions
) => {
  const user = useCurrentUser();

  const totalBudget =
    (state.status === 'answering' &&
      state.qualification.answers.caterer_minimum_order_value) ||
    0;
  const MAX_BUDGET_FOR_CUSTOMER_TIER_EXPERIMENT = 5000;

  const isLoggedIn = !!user.currentUserAccount;
  const currentStep =
    state.status === 'answering' ? state.qualification.step.id : null;

  const isQualifiedForCustomerTierExperiment =
    !isLoggedIn &&
    state.status === 'answering' &&
    currentStep === 'dietary_restrictions' &&
    totalBudget < MAX_BUDGET_FOR_CUSTOMER_TIER_EXPERIMENT &&
    isQualifiedForContactForm({
      user: user.currentUserAccount,
      answers: state.qualification.answers,
      selectedCaterer: state.qualification.selectedCaterer,
    });
  const isQualifiedForCustomerTierExperimentRef = useRef(
    isQualifiedForCustomerTierExperiment
  );
  isQualifiedForCustomerTierExperimentRef.current = isQualifiedForCustomerTierExperiment;

  interface ExperimentViewedEvent extends Event {
    detail: {
      experimentId: number;
      experimentName: string;
      projectId: number;
      projectName: string;
      variationId: number;
      variationName: string;
    };
  }

  const [isTierExperimentInitialized, setTierExperimentInitialized] = useState(
    isQualifiedForCustomerTierExperiment ? true : false
  );

  const isInitializedRef = useRef(isTierExperimentInitialized);
  isInitializedRef.current = isTierExperimentInitialized;

  const currentStepRef = useRef(currentStep);
  currentStepRef.current = currentStep;

  const handleExperiment = useCallback(
    (isQualified: boolean, variationId: number) => {
      if (!isQualified || !window.ablyft) {
        setTierExperimentInitialized(true);
        return;
      }

      const variation = Object.values(
        EXPERIMENT.customerTierABC.variations
      ).find((v) => v.id === variationId);

      if (variation) {
        if (
          variation.id === EXPERIMENT.customerTierABC.variations.original.id
        ) {
          actions.answerQuestion('customer_tier', CUSTOMER_TIER_CONTROL_VALUE);
        }

        actions.setCustomerTierExperiment(variation);
        setTierExperimentInitialized(true);
      }
    },
    [actions]
  );

  useEffect(
    function initializeTierExperiment() {
      if (currentStep !== 'dietary_restrictions') {
        return;
      }
      if (!window.ablyft || !isQualifiedForCustomerTierExperiment) {
        setTierExperimentInitialized(true);
        return;
      }

      if (!isTierExperimentInitialized) {
        setTimeout(() => {
          if (
            isInitializedRef.current ||
            currentStepRef.current !== 'dietary_restrictions'
          ) {
            return;
          }

          // Checking the variations would be unnecessary here, but the experimentViewed event is not fired on preview deploymewnts
          // So it's only a fallback for the preview deployment

          let variation: Variation | null = null;

          if (
            isVariationActive(EXPERIMENT.customerTierABC.variations.original)
          ) {
            variation = EXPERIMENT.customerTierABC.variations.original;
          }

          if (
            isVariationActive(EXPERIMENT.customerTierABC.variations.variationB)
          ) {
            variation = EXPERIMENT.customerTierABC.variations.variationB;
          }

          if (
            isVariationActive(EXPERIMENT.customerTierABC.variations.variationC)
          ) {
            variation = EXPERIMENT.customerTierABC.variations.variationC;
          }

          if (variation) {
            handleExperiment(
              isQualifiedForCustomerTierExperimentRef.current,
              variation.id
            );
          }

          setTierExperimentInitialized(true);
        }, 1500);
      }
    },
    [
      actions,
      currentStep,
      handleExperiment,
      isQualifiedForCustomerTierExperiment,
      isTierExperimentInitialized,
    ]
  );

  useEffect(() => {
    if (currentStep === 'people_count_and_budget') {
      setTierExperimentInitialized(false);
    }
  }, [currentStep, actions]);

  useEffect(() => {
    const handleExperimentEvent = (e: ExperimentViewedEvent) => {
      const isCustomerTierExperiment =
        e.detail.experimentId === EXPERIMENT.customerTierABC.id;

      if (isCustomerTierExperiment) {
        handleExperiment(
          isQualifiedForCustomerTierExperiment,
          e.detail.variationId
        );
      }
    };

    //@ts-expect-error untyped event
    document.addEventListener('viewedExperiment', handleExperimentEvent);
    return () => {
      //@ts-expect-error untyped event
      document.removeEventListener('viewedExperiment', handleExperimentEvent);
    };
  }, [isQualifiedForCustomerTierExperiment, handleExperiment, actions]);

  useEffect(() => {
    if (
      state.status === 'answering' &&
      state.qualification.step.id === 'dietary_restrictions'
    ) {
      if (
        !isQualifiedForCustomerTierExperiment &&
        state.qualification.variations?.length
      ) {
        actions.setCustomerTierExperiment(
          EXPERIMENT.customerTierABC.variations.original
        );
      }
    }
  }, [actions, isQualifiedForCustomerTierExperiment, state]);

  return { isQualifiedForCustomerTierExperiment, isTierExperimentInitialized };
};

import yup from 'lib/yup';
import { createQuestion } from 'qualification/schema/questions/utils';

export const peopleCountQuestion = createQuestion({
  id: 'min_order_number',
  getValidator: () => yup.number().min(1).required(),
  content: {
    titleEn: 'How many people will be served?',
    titleDe: 'Wie viele Personen sollen ca. verköstigt werden?',
  },
  suggestions: [
    {
      labelEn: '10 People',
      labelDe: '10 Personen',
      value: 10,
    },
    {
      labelEn: '25 People',
      labelDe: '25 Personen',
      value: 25,
    },
    {
      labelEn: '50 People',
      labelDe: '50 Personen',
      value: 50,
    },
    {
      labelEn: '100 People',
      labelDe: '100 Personen',
      value: 100,
    },
  ],
});

export type PeopleCountQuestion = typeof peopleCountQuestion;

import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import { Box } from '@heycater/design-system';

import yup from 'lib/yup';
import {
  AnsweringQualificationState,
  Answers,
  DEFAULT_QUALIFICATION_STATE,
  QUALIFICATION_STATUS,
  QualificationState,
} from 'qualification/context/model';
import {
  getActions,
  qualificationReducer,
} from 'qualification/context/reducer';
import { QualificationInitializeOptions } from 'qualification/context/reducer/initialize';
import { initialize as initializeFn } from 'qualification/context/reducer/initialize';
import { canGoToNextStep } from 'qualification/context/reducer/toNextStep';
import { useTierExperiment } from 'qualification/context/useTierExperiment';
import useCurrentUser from 'qualification/hooks/useCurrentUser';
import { useStorageReducer } from 'qualification/hooks/useStorageReducer';
import { Question, QuestionId, StepId } from 'qualification/schema/model';
import { SCHEMA_STEPS, STEP_IDS } from 'qualification/schema/steps';

/*
 * CONTEXT
 */
interface QualificationContext {
  state: QualificationState;
  actions: ReturnType<typeof getActions>;

  /**
   * Clears the session or localstorage, if state is stored there
   */
  clearSaved: () => void;
  answerOf: <T extends QuestionId>(questionId: T) => Answers[T] | undefined;
  errorsOf: (questionId: QuestionId) => string[];
  errorsOfStep: (stepId: StepId) => string[];
  canContinue: boolean;
}
export const QualificationContext = createContext<QualificationContext | null>(
  null
);

type Props = { initialized?: true | QualificationInitializeOptions } & (
  | {
      storageKey: string;
      storage: 'sessionStorage' | 'localStorage';
    }
  | { storage: null }
);

/*
 * PROVIDER
 */
export const QualificationProvider: React.FC<Props> = ({
  children,
  initialized,
  ...props
}) => {
  const [state, dispatch, clearSaved] = useStorageReducer(
    qualificationReducer,
    initialized
      ? initializeFn(
          DEFAULT_QUALIFICATION_STATE,
          typeof initialized === 'boolean' ? undefined : initialized
        )
      : DEFAULT_QUALIFICATION_STATE,
    'storageKey' in props
      ? {
          key: props.storageKey,
          isInvalid: isDataInStorageInvalid,
          storage: props.storage,
        }
      : { storage: null, isInvalid: isDataInStorageInvalid }
  );

  useEffect(() => {
    if (initialized) {
      const initialData = typeof initialized === 'object' ? initialized : {};
      actions.initialize({ ...initialData });
    }
  }, [initialized]);

  const actions = useMemo(() => getActions(dispatch), []);

  useCurrentUser();

  const answers = 'qualification' in state ? state.qualification.answers : null;
  const errors =
    'qualification' in state && 'errors' in state.qualification
      ? state.qualification.errors
      : null;

  const answerOf = useCallback(
    <T extends QuestionId>(questionId: T): Answers[T] | undefined => {
      if (answers) {
        return answers[questionId];
      }
    },
    [answers]
  );

  const errorsOfQuestion = useCallback(
    (questionId: QuestionId): string[] => {
      if (!errors) {
        return [];
      }
      return errors[questionId] || [];
    },
    [errors]
  );

  const errorsOfStep = useCallback(
    (stepId: StepId): string[] => {
      if (!errors) {
        return [];
      }

      const isAnswered = SCHEMA_STEPS[stepId].questions
        .map((question) => answers?.[question.id] || [])
        .flat(Infinity).length;
      if (!isAnswered) return [];

      const questionErrors = SCHEMA_STEPS[stepId].questions
        .map((question) => errors[question.id] || [])
        .filter((array) => array.length > 0)
        .flat();

      return questionErrors || [];
    },
    [errors, answers]
  );

  // Answer default values
  useEffect(() => {
    if (state.status !== QUALIFICATION_STATUS.answering) {
      return;
    }
    const currentStepId = state.qualification.step.id;
    const step = SCHEMA_STEPS[currentStepId];

    step.questions.forEach((child) =>
      answerDefaultValue(child, state, actions)
    );
  }, [state, answerOf, actions]);

  const {
    isTierExperimentInitialized,
    isQualifiedForCustomerTierExperiment,
  } = useTierExperiment(state, actions);

  const currentStep =
    state.status === 'answering' && state.qualification.step.id;

  const canContinue = useMemo(() => {
    if (
      currentStep === 'dietary_restrictions' &&
      !isTierExperimentInitialized
    ) {
      // Disable the next button, unless the experiment is initialized
      return false;
    }

    return canGoToNextStep(state);
  }, [currentStep, isTierExperimentInitialized, state]);

  const shouldTriggerTierExperimentForABLyft = isQualifiedForCustomerTierExperiment;

  return (
    <QualificationContext.Provider
      value={{
        state,
        actions,
        clearSaved: clearSaved,
        answerOf,
        errorsOfStep: errorsOfStep,
        errorsOf: errorsOfQuestion,
        canContinue,
      }}
    >
      <Box
        display="contents"
        id="qualification-provider"
        /* This is used to trigger the experiment on ABLYft's side. 
        The experiment is triggered when it finds a DOM node with a data-exp-customer-tiers attribute  
        Learn more at: https://app.ablyft.com/pages/74419923/edit 
        */
        data-exp-customer-tiers={shouldTriggerTierExperimentForABLyft}
      >
        {children}
      </Box>
    </QualificationContext.Provider>
  );
};

/*
 * HOC
 */
export function withQualificationProvider<T extends object>(
  props: Props,
  Component: React.FC<T>
): React.FC<T> {
  return function withHOC(componentProps: T) {
    return (
      <QualificationProvider {...props}>
        <Component {...componentProps} />
      </QualificationProvider>
    );
  };
}

function answerDefaultValue(
  question: Question,
  state: AnsweringQualificationState,
  actions: ReturnType<typeof getActions>
) {
  if (state.qualification.answers[question.id] !== undefined) {
    return;
  }

  if ('getDefaultValue' in question) {
    const defaultValue = question.getDefaultValue?.({
      answers: state.qualification.answers,
      selectedCaterer: state.qualification.selectedCaterer,
    });
    if (defaultValue !== undefined) {
      actions.answerQuestion(question.id, defaultValue);
    }
  }
}

function isDataInStorageInvalid(data: unknown) {
  try {
    const validator = yup.object({
      qualification: yup.object({
        steps: yup.array().of(
          yup.object({
            id: yup.mixed().oneOf(Object.values(STEP_IDS)).required(),
          })
        ),
        questions: yup.mixed().oneOf([undefined]),
      }),
    });
    validator.validateSync(data);
    return false;
  } catch {
    return true;
  }
}

export { useQualification } from './useQualification';

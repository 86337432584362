import { Caterer } from 'lib/algolia/model';
import { QuestionId, StepId } from 'qualification/schema/model';
import { CateringCategoryValue } from 'qualification/schema/questions/cateringCategoriesQuestion';
import { CityValue } from 'qualification/schema/questions/cityQuestion';
import { CustomerTierValue } from 'qualification/schema/questions/customerTierQuestion';
import { LeadType } from 'qualification/schema/questions/leadTypeQuestion';
import { Bestseller } from 'shared/bestsellers';

interface StateCore {
  initialOptions?: {
    nonSkippableQuestions?: StepId[];
  };
  reorderOfferId?: string | string[] | null;
  _firstAnsweredBudgetField?: 'peopleCount' | 'totalBudget';
  _isPeopleCountTouched?: boolean;
}

export type StepState = {
  id: StepId;
  disabled?: boolean;
};
interface QualificationCore {
  leadSource?: string;
  steps: StepState[];
  answers: Answers;
  selectedCaterer?: Caterer;
  selectedMenu?: Bestseller;
  variations?: number[];
}

export const QUALIFICATION_STATUS = {
  initial: 'initial',
  answering: 'answering',
  answered: 'answered',
  qualifiedForRequest: 'qualified-for-request',
  qualifiedForMarketplace: 'qualified-for-marketplace',
} as const;

type Keys = keyof typeof QUALIFICATION_STATUS;
export type QualificationStatus = typeof QUALIFICATION_STATUS[Keys];

export interface InitialQualificationState extends StateCore {
  status: typeof QUALIFICATION_STATUS['initial'];
}

export interface AnsweringQualificationState extends StateCore {
  status: typeof QUALIFICATION_STATUS['answering'];
  qualification: QualificationCore & {
    errors: Partial<Record<QuestionId, string[]>>;
    steps: StepState[];
    step: StepState;
    stepIndex: number;
    progressPercentage: number;
  };
}
export interface AnsweredQualificationState extends StateCore {
  status: typeof QUALIFICATION_STATUS['answered'];
  qualification: QualificationCore;
}

export interface RequestQualificationState extends StateCore {
  status: typeof QUALIFICATION_STATUS['qualifiedForRequest'];
  qualification: QualificationCore;
}
export interface MarketplaceQualificationState extends StateCore {
  status: typeof QUALIFICATION_STATUS['qualifiedForMarketplace'];
  marketplaceUrl: string;
  qualification: QualificationCore;
}

export type Answers = {
  city?: CityValue;
  catering_categories?: CateringCategoryValue[];
  event_date?: Date;
  lead_type?: LeadType;
  min_order_number?: number;
  customer_tier?: CustomerTierValue;

  has_recurring_need?: boolean;
  is_multi_day?: boolean;

  caterer_minimum_order_value?: number;
  price_per_person?: number;
  number_of_carnivore?: number;
  number_of_vegans?: number;
  number_of_vegetarians?: number;
  rare_customer_tier?: string;
};

export type QualificationState =
  | InitialQualificationState
  | AnsweringQualificationState
  | AnsweredQualificationState
  | RequestQualificationState
  | MarketplaceQualificationState;

export const DEFAULT_QUALIFICATION_STATE: InitialQualificationState = Object.freeze(
  {
    status: QUALIFICATION_STATUS.initial,
    currentUserAccount: null,
  }
);

import { useContext, useEffect } from 'react';

import { QualificationContext } from '.';

import {
  QUALIFICATION_STATUS,
  QualificationState,
  StepState,
} from 'qualification/context/model';
import usePrevious from 'shared/hooks/usePrevious';

/*
 * HOOK
 */
interface HookOptions {
  onQuestionChange?: (question?: StepState | null) => void;
  onAllQuestionAnswered?: (state: QualificationState) => void;
}

export function useQualification({
  onQuestionChange,
  onAllQuestionAnswered,
}: HookOptions = {}) {
  const context = useContext(QualificationContext);
  if (!context) {
    throw new Error(
      "useQualification hook is used outside of it's context provider"
    );
  }

  const status = context.state.status;
  const prevStatus = usePrevious(status);

  const question =
    status === QUALIFICATION_STATUS.answering
      ? context.state.qualification.step
      : undefined;

  const prevQuestionId = usePrevious(
    status === QUALIFICATION_STATUS.answering
      ? context.state.qualification.step?.id
      : undefined
  );
  // onAllQuestionAnswered
  useEffect(() => {
    if (
      status === QUALIFICATION_STATUS.answered &&
      prevStatus !== QUALIFICATION_STATUS.answered &&
      onAllQuestionAnswered
    ) {
      onAllQuestionAnswered(context.state);
    }
  }, [status, prevStatus]);

  // onQuestionChange
  useEffect(() => {
    if (!onQuestionChange || status !== QUALIFICATION_STATUS.answering) {
      return;
    }
    if (prevQuestionId !== question?.id) {
      onQuestionChange(question);
    }
  }, [onQuestionChange, prevQuestionId, question]);

  return context;
}

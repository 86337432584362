import {
  AnsweringQualificationState,
  Answers,
  QualificationState,
  StepState,
} from 'qualification/context/model';
import { Question, QuestionId, StepId } from 'qualification/schema/model';
import { SCHEMA_QUESTIONS } from 'qualification/schema/questions';
import { SCHEMA_STEPS } from 'qualification/schema/steps';

export function getAnswer<T extends QuestionId>(
  state: QualificationState,
  questionId: T
): Answers[T] | undefined {
  return 'qualification' in state
    ? state.qualification.answers[questionId]
    : undefined;
}

export function getErrors<T extends Question & { errors?: string[] }>(
  state: Extract<QualificationState, { qualification: { errors: unknown } }>,
  question: T
): string[] | undefined {
  return state.qualification.errors[question.id];
}

export function isStepValid(
  state: Extract<QualificationState, { qualification: { step: unknown } }>,
  stepId: StepId
): boolean {
  const step = SCHEMA_STEPS[stepId];
  return step.questions
    .map((child) => isQuestionValid(state, child.id))
    .every((item) => !!item);
}

export function isQuestionValid(
  state: Extract<QualificationState, { qualification: { step: unknown } }>,
  questionId: QuestionId
): boolean {
  const question = SCHEMA_QUESTIONS[questionId];
  const answer = state.qualification.answers[question.id];
  const errors = state.qualification.errors[question.id];

  return !errors?.length && answer !== undefined;
}

export function getStepIndexAndPercentage(step: StepState, steps: StepState[]) {
  const enabledSteps = steps.filter((q) => !q.disabled);
  const stepIndex = enabledSteps.findIndex((_step) => _step.id === step.id);
  const progressPercentage = Math.round(
    (stepIndex / (enabledSteps.length - 1)) * 100
  );
  return { stepIndex, progressPercentage };
}

export function getFirstUnansweredStep(
  state: Extract<
    QualificationState,
    { qualification: { answers: unknown; errors: unknown } }
  >
) {
  return state.qualification.steps.find((q) => !isStepValid(state, q.id));
}

export function getFirstShownStep(
  state: Extract<
    QualificationState,
    { qualification: { answers: unknown; errors: unknown } }
  >
) {
  const nonSkippableQuestions = state.initialOptions?.nonSkippableQuestions;

  const firstStepToShow = state.qualification.steps.find((step, index) => {
    const isLastStep = index === state.qualification.steps.length - 1;

    if (step.disabled) {
      return false;
    }
    const isCorrectlyAnswered = isStepValid(state, step.id);
    if (isCorrectlyAnswered) {
      return false;
    }

    const canBeSkipped = nonSkippableQuestions
      ? !nonSkippableQuestions.includes(step.id)
      : true;

    if (!canBeSkipped) {
      return true;
    }

    if (isLastStep) {
      return true;
    }

    return true;
  });
  return (
    firstStepToShow || state.qualification.steps.filter((q) => !q.disabled)[0]
  );
}

export function disableStep(
  state: AnsweringQualificationState,
  stepId: StepId
): void {
  const step = state.qualification.steps.find((step) => step.id === stepId);
  if (step) {
    step.disabled = true;
  }
}

import {
  AnsweredQualificationState,
  QUALIFICATION_STATUS,
  QualificationState,
} from 'qualification/context/model';
import {
  getStepIndexAndPercentage,
  isStepValid,
} from 'qualification/context/reducer/utils';

export function toNextStep<T extends QualificationState>(
  state: T,
  onFunnelFinished?: (state: QualificationState) => void
): QualificationState {
  if (state.status !== QUALIFICATION_STATUS.answering) {
    return state;
  }

  const step = state.qualification.step;
  if (!step) {
    return state;
  }
  const stepIndex = state.qualification.steps.findIndex(
    (_step) => _step.id === step.id
  );
  const nextStep = state.qualification.steps[stepIndex + 1];

  if (!nextStep || nextStep?.disabled) {
    const funnelAnsweredState: AnsweredQualificationState = {
      ...state,
      status: 'answered',
      qualification: {
        ...state.qualification,
        answers: state.qualification.answers,
        steps: state.qualification.steps,
        selectedCaterer: state.qualification.selectedCaterer,
      },
    };

    if (onFunnelFinished) {
      onFunnelFinished(funnelAnsweredState);
    }
    return funnelAnsweredState;
  }

  const {
    stepIndex: newStepIndex,
    progressPercentage,
  } = getStepIndexAndPercentage(nextStep, state.qualification.steps);
  return {
    ...state,
    qualification: {
      ...state.qualification,
      selectedCaterer: state.qualification.selectedCaterer,
      step: nextStep,
      stepIndex: newStepIndex,
      progressPercentage,
    },
  };
}

export function canGoToNextStep<T extends QualificationState>(
  state: T
): boolean {
  if (state.status !== QUALIFICATION_STATUS.answering) {
    return false;
  }

  const step = state.qualification.step;
  if (!step) {
    return false;
  }

  const isCurrentStepAnsweredCorrectly = isStepValid(
    state,
    state.qualification.step.id
  );

  if (!isCurrentStepAnsweredCorrectly) {
    return false;
  }

  const stepIndex = state.qualification.steps.findIndex(
    (_step) => _step.id === step.id
  );
  const prevSteps = state.qualification.steps.slice(0, stepIndex);

  if (prevSteps.length) {
    const arePrevStepsAnsweredCorrectly = prevSteps.every((q) => {
      const isValid = isStepValid(state, q.id);
      return isValid;
    });
    if (!arePrevStepsAnsweredCorrectly) {
      return false;
    }
  }

  return true;
}

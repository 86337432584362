import { addDays, getDay } from 'date-fns';

import yup from 'lib/yup';
import {
  createDateQuestion,
  createQuestion,
} from 'qualification/schema/questions/utils';

export const DEFAULT_LEAD_DAYS = 2;

export const isMultipleDaysQuestion = createQuestion({
  id: 'is_multi_day',
  getDefaultValue: () => false,
  getValidator: () => yup.boolean(),
});

export const isRecurringQuestion = createQuestion({
  id: 'has_recurring_need',
  getDefaultValue: () => false,
  condition: (isLoggedIn) => !isLoggedIn,
  getValidator: () => yup.boolean(),
});

export const dateQuestion = createDateQuestion({
  id: 'event_date',
  getValidator: ({ selectedCaterer }) => {
    const leadDays = selectedCaterer
      ? selectedCaterer.numeric_lead_time
      : DEFAULT_LEAD_DAYS;
    const minDate = addDays(new Date(), leadDays - 1);

    let validator = yup.date().min(minDate).required();

    if (selectedCaterer) {
      const weeklyAvailability = [
        selectedCaterer.available_on_monday,
        selectedCaterer.available_on_tuesday,
        selectedCaterer.available_on_wednesday,
        selectedCaterer.available_on_thursday,
        selectedCaterer.available_on_friday,
        selectedCaterer.available_on_saturday,
        selectedCaterer.available_on_sunday,
      ];
      const isAvailableEveryDay = weeklyAvailability.every(
        (isAvailable) => isAvailable === true
      );

      if (!isAvailableEveryDay) {
        validator = validator.test('is-caterer-available', (date) => {
          if (!(date instanceof Date)) {
            return false;
          }
          const weekDay = getDay(date);
          return !!weeklyAvailability[weekDay];
        });
      }
    }
    return validator;
  },
  getAttributes: ({ selectedCaterer }) => {
    const leadDays = selectedCaterer
      ? selectedCaterer.numeric_lead_time
      : DEFAULT_LEAD_DAYS;

    return {
      minDate: {
        leadTimeDays: leadDays,
        messageEn: `The lead time must be at least ${leadDays} days`,
        messageDe: `Die Vorlaufzeit muss mind. ${leadDays} Tage betragen`,
      },
    };
  },
});

import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

import { Caterer } from 'lib/algolia/model';
import { useQualification } from 'qualification/context';
import { Answers } from 'qualification/context/model';
import useCurrentUser, {
  CurrentUser,
} from 'qualification/hooks/useCurrentUser';
import { buildFilteredMarketplaceURL } from 'qualification/services/buildFilteredMarketplaceURL';
import { LEAD_TYPE, MIN_BUDGET } from 'shared/constants';

const MAX_TIME_BEFORE_REDIRECT = 1800;

export function useQualifyUser() {
  const router = useRouter();
  const { actions, state } = useQualification();
  const { currentUserAccount } = useCurrentUser();
  const redirectTimeoutRef = useRef<null | NodeJS.Timeout>(null);

  useEffect(() => {
    if (!('qualification' in state)) {
      return;
    }
    const answers = state.qualification.answers;

    const qualifiedForContactForm = isQualifiedForContactForm({
      answers,
      user: currentUserAccount,
      selectedCaterer: state.qualification?.selectedCaterer,
    });
    function qualify() {
      if (qualifiedForContactForm) {
        actions.qualify({ to: 'request' });
      } else {
        actions.qualify({
          to: 'marketplace',
          url: buildFilteredMarketplaceURL(answers, router.locale),
        });
      }
    }

    redirectTimeoutRef.current = setTimeout(
      qualify,
      Math.random() * MAX_TIME_BEFORE_REDIRECT
    );

    return () => {
      if (redirectTimeoutRef.current) {
        clearTimeout(redirectTimeoutRef.current);
      }
    };
  }, []);
}

interface QualifyArgs {
  user?: CurrentUser | null;
  answers: Answers;
  selectedCaterer?: Caterer;
}

export function isQualifiedForContactForm({
  user,
  answers,
  selectedCaterer,
}: QualifyArgs) {
  const isPrivateLeadType = !user && answers.lead_type === LEAD_TYPE.PRIVATE;
  const isHighBudget =
    answers?.caterer_minimum_order_value &&
    answers?.caterer_minimum_order_value >= MIN_BUDGET;

  const isLowBudget = !isHighBudget;

  const hasADMProcess = user?.owner?.admStage === 'account-managed';
  const isManagedAccount = !!user?.owner?.salesforceCustomer?.isManagedAccount;
  const hasRecurringNeed = !!answers.has_recurring_need;

  if (selectedCaterer) {
    return true;
  }

  if (isPrivateLeadType) {
    return false;
  }

  if (isHighBudget) {
    return true;
  }

  if (isLowBudget) {
    if (hasRecurringNeed) return true;
    if (isManagedAccount) return true;
    if (hasADMProcess) return true;
  }

  return false;
}

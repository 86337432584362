import deepmerge from 'deepmerge';

import { EXPERIMENT, Variation } from 'lib/ablyft';
import {
  DEFAULT_QUALIFICATION_STATE,
  QUALIFICATION_STATUS,
  QualificationState,
} from 'qualification/context/model';
import { answerQuestion } from 'qualification/context/reducer/answerQuestion';
import {
  initialize,
  QualificationInitializeOptions,
} from 'qualification/context/reducer/initialize';
import { setQuestion } from 'qualification/context/reducer/setQuestion';
import { toNextStep } from 'qualification/context/reducer/toNextStep';
import { toPrevStep } from 'qualification/context/reducer/toPrevStep';
import { getStepIndexAndPercentage } from 'qualification/context/reducer/utils';
import { QuestionId, StepId } from 'qualification/schema/model';
import { steps } from 'qualification/schema/steps';
import { Bestseller } from 'shared/bestsellers';

const DEBUG = false;

interface AnswerPayload {
  id: Exclude<QuestionId, 'budget' | 'dietary_restrictions'>;
  value: unknown;
  options?: AnswerPayloadOptions;
}

type Action<T extends string, K = undefined> = K extends undefined
  ? { type: T }
  : { type: T; payload: K };

type UserQualification = { to: 'marketplace'; url: string } | { to: 'request' };

export type QualificationDispatchAction =
  | Action<'initialize', QualificationInitializeOptions>
  | Action<'reset'>
  | Action<'answerQuestion', AnswerPayload>
  | Action<'selectBestseller', Bestseller>
  | Action<'clearBestseller'>
  | Action<'nextStep'>
  | Action<'prevStep'>
  | Action<'setQuestion', StepId>
  | Action<'setRequest', unknown>
  | Action<'setReorderOfferId', string | string[] | null>
  | Action<'qualify', UserQualification>
  | Action<
      'setCustomerTierExperiment',
      typeof EXPERIMENT.customerTierABC.variations[keyof typeof EXPERIMENT.customerTierABC.variations]
    >
  | Action<'addRareCustomerTierStep'>
  | Action<'removeRareCustomerTierStep'>;

interface AnswerPayloadOptions {
  isManagedAccount?: boolean;
}
export const qualificationReducer = (
  state: QualificationState,
  action: QualificationDispatchAction
): QualificationState => {
  if (DEBUG) {
    const { type, ...rest } = action;
    console.groupCollapsed('QUALIFICATION: ' + type);
    if (rest) {
      console.log(JSON.stringify(rest, null, 2));
    }
    console.groupEnd();
  }

  switch (action.type) {
    case 'initialize': {
      return initialize(state, action.payload);
    }
    case 'reset': {
      return DEFAULT_QUALIFICATION_STATE;
    }
    case 'answerQuestion': {
      if (state.status !== QUALIFICATION_STATUS.answering) {
        return state;
      }

      return answerQuestion(state, action.payload);
    }
    case 'nextStep': {
      return toNextStep(state);
    }
    case 'prevStep': {
      return toPrevStep(state);
    }
    case 'setQuestion': {
      if (state.status !== QUALIFICATION_STATUS.answering) {
        return state;
      }
      return setQuestion(state, action.payload);
    }
    case 'setReorderOfferId': {
      return {
        ...state,
        reorderOfferId: action.payload,
      };
    }
    case 'setRequest': {
      if (state.status !== QUALIFICATION_STATUS.qualifiedForRequest) {
        return state;
      }
      return deepmerge(state, {
        qualification: { request: action.payload },
      });
    }
    case 'qualify': {
      if (state.status !== QUALIFICATION_STATUS.answered) {
        return state;
      }
      if (action.payload.to === 'marketplace') {
        return {
          ...state,
          status: QUALIFICATION_STATUS.qualifiedForMarketplace,
          marketplaceUrl: action.payload.url,
        };
      }
      if (action.payload.to === 'request') {
        return {
          ...state,
          status: QUALIFICATION_STATUS.qualifiedForRequest,
        };
      }
      return state;
    }
    case 'clearBestseller': {
      if (state.status === 'answering') {
        const newState = answerQuestion(state, {
          id: 'catering_categories',
          value: undefined,
        });

        return initialize(newState, {
          answers: newState.qualification.answers,
          selectedCaterer: newState.qualification.selectedCaterer,
          selectedMenu: undefined,
        });
      }
      return state;
    }
    case 'selectBestseller': {
      if (state.status === 'answering') {
        const newState = answerQuestion(state, {
          id: 'catering_categories',
          value: undefined,
        });

        const answers = newState.qualification.answers;

        return initialize(newState, {
          answers,
          selectedCaterer: newState.qualification.selectedCaterer,
          selectedMenu: action.payload,
        });
      }
      return state;
    }
    case 'setCustomerTierExperiment': {
      const variation = action.payload;

      if (state.status !== 'answering') {
        return state;
      }

      const lastStep =
        state.qualification.steps[state.qualification.steps.length - 1];
      if (variation.id === EXPERIMENT.customerTierABC.variations.original.id) {
        const isAlreadyCorrect = lastStep.id === 'dietary_restrictions';
        if (isAlreadyCorrect) {
          return state;
        }
        const newSteps = [...state.qualification.steps];
        newSteps.pop();
        const { progressPercentage } = getStepIndexAndPercentage(
          state.qualification.step,
          newSteps
        );
        return {
          ...state,
          qualification: {
            ...state.qualification,
            steps: newSteps,
            progressPercentage: progressPercentage,
          },
        };
      }

      if (
        variation.id === EXPERIMENT.customerTierABC.variations.variationB.id ||
        variation.id === EXPERIMENT.customerTierABC.variations.variationC.id
      ) {
        const isAlreadyCorrect = lastStep.id === steps.customerTier.id;
        if (isAlreadyCorrect) {
          return state;
        }
        if (
          state.qualification.steps.find(
            ({ id }) => id === steps.customerTier.id
          )
        ) {
          return state;
        }
        const newSteps = [...state.qualification.steps];
        newSteps.push({ id: steps.customerTier.id });
        const { progressPercentage } = getStepIndexAndPercentage(
          state.qualification.step,
          newSteps
        );
        return {
          ...state,
          qualification: {
            ...state.qualification,
            steps: [...state.qualification.steps, steps.customerTier],
            progressPercentage: progressPercentage,
          },
        };
      }
      return state;
    }
    case 'addRareCustomerTierStep': {
      if (state.status !== 'answering') {
        return state;
      }
      if (
        state.qualification.steps.find(
          ({ id }) => id === steps.rareCustomerTierStep.id
        )
      ) {
        return state;
      }

      const newSteps = [
        ...state.qualification.steps,
        steps.rareCustomerTierStep,
      ];
      const { progressPercentage } = getStepIndexAndPercentage(
        state.qualification.step,
        newSteps
      );

      return {
        ...state,
        qualification: {
          ...state.qualification,
          steps: newSteps,
          progressPercentage: progressPercentage,
        },
      };
    }
    case 'removeRareCustomerTierStep': {
      if (state.status !== 'answering') {
        return state;
      }
      const newSteps = [
        ...state.qualification.steps.filter(
          (step) => step.id != steps.rareCustomerTierStep.id
        ),
      ];
      const { progressPercentage } = getStepIndexAndPercentage(
        state.qualification.step,
        newSteps
      );
      return {
        ...state,
        qualification: {
          ...state.qualification,
          steps: [
            ...state.qualification.steps.filter(
              (step) => step.id != steps.rareCustomerTierStep.id
            ),
          ],
          progressPercentage: progressPercentage,
        },
      };
    }
    default: {
      const _exhaustiveCheck: never = action;
      return state;
    }
  }
};

export function getActions(
  dispatch: React.Dispatch<QualificationDispatchAction>
) {
  return {
    answerQuestion: (
      questionId: AnswerPayload['id'],
      answer: unknown,
      options?: unknown
    ) =>
      dispatch({
        type: 'answerQuestion',
        payload: { id: questionId, value: answer, options: options || {} },
      }),
    initialize: (options?: QualificationInitializeOptions) =>
      dispatch({ type: 'initialize', payload: options || {} }),
    nextStep: () => dispatch({ type: 'nextStep' }),
    prevStep: () => dispatch({ type: 'prevStep' }),
    reset: () => dispatch({ type: 'reset' }),
    selectBestseller: (bestseller: Bestseller) =>
      dispatch({ type: 'selectBestseller', payload: bestseller }),
    clearBestseller: () => dispatch({ type: 'clearBestseller' }),
    setQuestion: (questionId: StepId) =>
      dispatch({ type: 'setQuestion', payload: questionId }),
    setReorderOfferId: (offerId: string | string[] | null) =>
      dispatch({ type: 'setReorderOfferId', payload: offerId }),
    setRequest: (requestFormData: unknown) =>
      dispatch({ type: 'setRequest', payload: requestFormData }),
    qualify: (qualified: UserQualification) =>
      dispatch({ type: 'qualify', payload: qualified }),
    setCustomerTierExperiment: (variation: Variation) =>
      dispatch({ type: 'setCustomerTierExperiment', payload: variation }),
    addRareCustomerTierStep: () =>
      dispatch({ type: 'addRareCustomerTierStep' }),
    removeRareCustomerTierStep: () =>
      dispatch({ type: 'removeRareCustomerTierStep' }),
  } as const;
}

export type QualificationActions = ReturnType<typeof getActions>;

import { stringify } from 'qs';

import { Answers } from 'qualification/context/model';
import LocalizedAlgoliaAttributes from 'qualification/services/LocalizedAlgoliaAttributes';
import { toUnixTimestamp } from 'shared/helpers/dates';
import { marketplaceURL } from 'shared/helpers/urls';

interface QueryParts {
  qualified: boolean;
  lng?: string;
  refinementList: { [key: string]: unknown };
  range: {
    price_per_person?: {
      max: number;
    };
    min_order_number: {
      max: number;
    };
  };
  event_date?: string;
}

export function buildFilteredMarketplaceURL(
  answers: Answers,
  locale: string | undefined
) {
  const localizedAlgoliaAttributes = new LocalizedAlgoliaAttributes({
    filters: answers,
    locale,
  });

  const city = answers.city || 'berlin';

  const queryParts: QueryParts = {
    qualified: true,
    lng: locale,
    refinementList: {
      [localizedAlgoliaAttributes.localizedFieldName(
        'catering_categories'
      )]: localizedAlgoliaAttributes.generateLocalizedFieldValues(
        'catering_categories'
      ),
    },
    range: {
      min_order_number: {
        max: answers.min_order_number || 0,
      },
    },
  };

  if (answers.event_date) {
    queryParts.event_date = toUnixTimestamp(new Date(answers.event_date));
  }

  queryParts.range.price_per_person = {
    max: (answers.price_per_person || 0) * 100,
  };

  return marketplaceURL(`/catering/${city}?${stringify(queryParts)}`);
}

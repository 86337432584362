import yup from 'lib/yup';
import { createQuestion } from 'qualification/schema/questions/utils';

export const CUSTOMER_TIER_CONTROL_VALUE = 'control-group' as const;
export const CUSTOMER_TIER_B_VALUES = [
  'single-use-b',
  'recurring-request-b',
  'food-program-b',
] as const;

export const CUSTOMER_TIER_C_VALUES = [
  'single-use-c',
  'recurring-request-c',
  'food-program-c',
] as const;

export const FREQUENCY_CUSTOMER_TIER_C_VALUES = [
  'rare-b',
  'rare-c-ir',
  'regular',
  'frequent',
] as const;

export const customerTier = {
  controlGroup: 'control-group',
  singleUseB: 'single-use-b',
  recurringB: 'recurring-request-b',
  foodProgramB: 'food-program-b',
  singleUseC: 'single-use-c',
  recurringC: 'recurring-request-c',
  foodProgramC: 'food-program-c',
  rareV1: 'rare-b',
  rareV2: 'rare-c-ir',
  regular: 'regular',
  frequent: 'frequent',
} as const;

type FrequecnyCustomerTierC = typeof FREQUENCY_CUSTOMER_TIER_C_VALUES[number];
type CustomerTierControl = typeof CUSTOMER_TIER_CONTROL_VALUE;
type CustomerTierB = typeof CUSTOMER_TIER_B_VALUES[number];
type CustomerTierC = typeof CUSTOMER_TIER_C_VALUES[number];

export type CustomerTierValue =
  | CustomerTierControl
  | CustomerTierB
  | CustomerTierC;

export const customerTierQuestion = createQuestion({
  id: 'customer_tier',
  getValidator: () =>
    yup
      .mixed<FrequecnyCustomerTierC>()
      .oneOf([...FREQUENCY_CUSTOMER_TIER_C_VALUES])
      .required(),
  content: {
    titleEn: 'Customer tier',
    titleDe: 'Kundengruppe',
  },
});

export const rareCustomerTierQuestion = createQuestion({
  id: 'rare_customer_tier',
  getValidator: () => yup.string().nullable(),
  content: {
    titleEn: 'Customer tier',
    titleDe: 'Kundengruppe',
  },
});

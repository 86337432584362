import {
  QUALIFICATION_STATUS,
  QualificationState,
} from 'qualification/context/model';
import { getStepIndexAndPercentage } from 'qualification/context/reducer/utils';

export function toPrevStep(state: QualificationState): QualificationState {
  if (state.status === QUALIFICATION_STATUS.qualifiedForRequest) {
    const lastQuestion =
      state.qualification.steps[state.qualification.steps.length - 1];
    const {
      stepIndex: stepIndex,
      progressPercentage,
    } = getStepIndexAndPercentage(lastQuestion, state.qualification.steps);

    return {
      ...state,
      qualification: {
        ...state.qualification,
        step: lastQuestion,
        stepIndex: stepIndex,
        progressPercentage,
        errors: {},
      },
      status: QUALIFICATION_STATUS.answering,
    };
  }

  if (state.status !== QUALIFICATION_STATUS.answering) {
    return state;
  }

  const step = state.qualification.step;
  if (!step) {
    return state;
  }
  const stepIndex = state.qualification.steps.findIndex(
    (_step) => _step.id === step.id
  );
  const prevStep = state.qualification.steps[stepIndex - 1];

  if (!prevStep || prevStep?.disabled) {
    return state;
  }

  const {
    stepIndex: newStepIndex,
    progressPercentage,
  } = getStepIndexAndPercentage(prevStep, state.qualification.steps);

  return {
    ...state,
    qualification: {
      ...state.qualification,
      step: prevStep,
      stepIndex: newStepIndex,
      progressPercentage: progressPercentage,
    },
  };
}

import { Dispatch, Reducer, useCallback, useEffect, useReducer } from 'react';

const initializer = (
  key: string,
  storage: StorageName,
  isInvalid?: (data: unknown) => boolean
) => <T>(initial: T) => {
  const storageFn = storage === 'localStorage' ? localStorage : sessionStorage;
  const stored = storageFn.getItem(key);
  if (!stored) return initial;
  const parsedData = { ...JSON.parse(stored) };
  if (isInvalid) {
    return isInvalid(parsedData) ? initial : parsedData;
  }
  return parsedData;
};

type Options = { isInvalid?: (parsedData: unknown) => boolean } & (
  | {
      key: string;
      storage: StorageName;
    }
  | { storage: null }
);

type StorageName = 'sessionStorage' | 'localStorage';

export const useStorageReducer = <T extends object, A>(
  reducer: Reducer<T, A>,
  initialState: T,
  options: Options
): [T, Dispatch<A>, VoidFunction] => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState,
    options.storage && typeof window !== 'undefined'
      ? initializer(options.key, options.storage, options.isInvalid)
      : () => initialState
  );

  const clearValue = useCallback(() => {
    if (options.storage && typeof window !== 'undefined') {
      const storageFn =
        options.storage === 'localStorage' ? localStorage : sessionStorage;
      storageFn.removeItem(options.key);
    }
  }, [options]);

  useEffect(() => {
    if (options.storage && typeof window !== 'undefined') {
      const storageFn =
        options.storage === 'localStorage' ? localStorage : sessionStorage;
      storageFn.setItem(options.key, JSON.stringify(state));
    }
  }, [state]);

  return [state, dispatch, clearValue];
};

const createStorageReducer = (
  storage: StorageName,
  isInvalid?: (data: unknown) => boolean
) => <T extends object, A>(
  reducer: Reducer<T, A>,
  initialState: T,
  key: string
): [T, Dispatch<A>, VoidFunction] =>
  useStorageReducer(reducer, initialState, { key, storage, isInvalid });

export const useSessionReducer = createStorageReducer('sessionStorage');
export const useLocalReducer = createStorageReducer('localStorage');

import capitalize from 'lodash/capitalize';

const localeConfig = {
  locales: ['en', 'de'],
  defaultLocale: 'de',
} as const;

type Locale = (typeof localeConfig)['locales'][number];

type LocalizedKey<T extends string> = `${T}_${Locale}`;

export const algoliaAttributeNameForLocale =
  (locale: unknown) =>
  <T extends string>(name: T): LocalizedKey<T> => {
    const toLocalizedAlgoliaKey = (name: T): LocalizedKey<T> => {
      const parsedLocale: Locale = localeConfig.locales.includes(
        locale as Locale
      )
        ? (locale as Locale)
        : localeConfig.defaultLocale;

      return `${name}_${parsedLocale}`;
    };

    if (name.includes('.')) {
      const [scope, fieldName] = name.split('.');
      // @ts-expect-error: edge case, hard to type
      return `${scope}.${toLocalizedAlgoliaKey(fieldName as T)}`;
    }
    return toLocalizedAlgoliaKey(name);
  };

export const attributeNameForLocale =
  (locale: string | undefined) =>
  <T extends string>(name: T) => {
    if (!localeConfig.locales.includes(locale as Locale))
      return `${name}${capitalize(localeConfig.defaultLocale)}`;

    return `${name}${capitalize(locale)}`;
  };

import {
  AnsweringQualificationState,
  QUALIFICATION_STATUS,
} from 'qualification/context/model';
import {
  getFirstUnansweredStep,
  getStepIndexAndPercentage,
  isStepValid,
} from 'qualification/context/reducer/utils';
import { StepId } from 'qualification/schema/model';

export function setQuestion(
  state: AnsweringQualificationState,
  stepId: StepId
) {
  if (state.status !== QUALIFICATION_STATUS.answering) {
    return state;
  }

  const step = state.qualification.steps.find((_step) => _step.id === stepId);

  if (step) {
    const stepIndex = state.qualification.steps.indexOf(step);
    const prevSteps = state.qualification.steps.slice(0, stepIndex);
    const arePrevStepsAnsweredCorrectly = prevSteps.every((_step) =>
      isStepValid(state, _step.id)
    );

    const newStep = arePrevStepsAnsweredCorrectly
      ? step
      : getFirstUnansweredStep(state);

    if (!newStep || newStep.disabled) {
      return state;
    }

    const {
      stepIndex: newStepIndex,
      progressPercentage,
    } = getStepIndexAndPercentage(newStep, state.qualification.steps);

    return {
      ...state,
      qualification: {
        ...state.qualification,
        stepIndex: newStepIndex,
        progressPercentage,
        step: {
          ...newStep,
          isValid: isStepValid(state, newStep.id),
        },
      },
    };
  }
  return state;
}

import i18next from 'i18next';

import yup from 'lib/yup';
import { PRICE_PER_PERSON_OF_CATEGORY } from 'qualification/schema/questions/cateringCategoriesQuestion';
import { createQuestion } from 'qualification/schema/questions/utils';

export const DEFAULT_BUDGET_PER_PERSON = 25;
export const MIN_PRICE_PER_PERSON = 10;

export const pricePerPersonQuestion = createQuestion({
  id: 'price_per_person',
  getDefaultValue: ({ answers }) => {
    if (!answers.catering_categories?.length) {
      return DEFAULT_BUDGET_PER_PERSON;
    }

    const categories = answers.catering_categories || [];
    const hasMultipleCategories = categories.length > 1;
    const category = hasMultipleCategories ? 'other' : categories[0] || 'other';

    const priceData = PRICE_PER_PERSON_OF_CATEGORY[category];
    return priceData.defaultPricePerPerson;
  },
  getValidator: ({ answers, isManagedAccount }) => {
    const categories = answers.catering_categories || [];
    const hasMultipleCategories = categories.length > 1;
    const category = hasMultipleCategories ? 'other' : categories[0] || 'other';
    const minPrice = isManagedAccount
      ? 1
      : PRICE_PER_PERSON_OF_CATEGORY[category].minPricePerPerson;

    return yup
      .number()
      .required()
      .min(
        minPrice,
        i18next.t('qualification:validations.pricePerPerson.lowForCategory', {
          budget: minPrice,
          category: i18next.t(
            `qualification:validations.pricePerPerson.category.${category}`
          ),
        })
      )
      .max(500);
  },
  content: {
    titleEn: 'Per person // Net value',
    titleDe: 'Pro Person // Netto',
    assistiveTextEn: 'Per person // Net value',
    assistiveTextDe: 'Pro Person // Netto',
    placeholderDe: 'z.B. 12',
    placeholderEn: 'e.g. 12',
  },
});

export const totalBudgetQuestion = createQuestion({
  id: 'caterer_minimum_order_value',
  getValidator: ({ selectedCaterer }) => {
    const minimumOrderPrice = selectedCaterer
      ? selectedCaterer.minimum_order_value / 100
      : 0;
    return yup.number().min(minimumOrderPrice).required();
  },
  content: {
    titleEn: 'Net value',
    titleDe: 'Netto',
    assistiveTextEn: 'Net value',
    assistiveTextDe: 'Netto',
    placeholderDe: 'z.B. 1200',
    placeholderEn: 'e.g. 1200',
  },
});

import { format } from 'date-fns';
import { de, enGB } from 'date-fns/locale';

const dateFormatByLocaleMapping = {
  en: 'dd/MM/yyyy',
  de: 'dd.MM.yy',
  da: 'dd/MM/yyyy',
  'da-dk': 'dd/MM/yyyy',
  'en-uk': 'dd/MM/yyyy',
  'en-de': 'dd/MM/yyyy',
  'de-at': 'dd.MM.yy',
  'de-ch': 'dd.MM.yy',
  'de-de': 'dd.MM.yy',
};

export const localeToDateLocaleMapping = {
  en: enGB,
  de,
  da: enGB,
  'da-dk': enGB,
  'en-uk': enGB,
  'en-de': enGB,
  'de-at': de,
  'de-ch': de,
  'de-de': de,
};

export function formatDate(dateString, locale) {
  if (dateString === null || typeof dateString === 'undefined') {
    return null;
  }

  return format(new Date(dateString), dateFormatByLocaleMapping[locale], {
    locale: localeToDateLocaleMapping[locale],
  });
}

export function toUnixTimestamp(date) {
  return (date.getTime() / 1000).toFixed(0);
}

export function getDayNameFromDate(date) {
  return date ? format(date, 'EEEE').toLowerCase() : undefined;
}
